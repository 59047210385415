import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import withAppContext from '../../../withAppContext';
import { colors, mobileThresholdPixels } from './styledComponents';
import { pricingInverse } from '../../../services/pricing';
import { items, embroideriesLabels } from './pricesTree';
import Feedback from './Feedback';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 811px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: calc(100vw - 48px);
  }
`;

const FeedbackContainer = styled.div`
  width: 100%;
  background-color: ${colors.mediumGrey};
  border: 0.75px solid ${colors.blueGrey};
  box-sizing: border-box;
  border-radius: 3px;
  margin-top: 30px;
  padding: 37px 45px;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 24px 4px;
    margin-top: 24px;
  }
`;

const PricesContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${colors.blueGrey};
  border-radius: 3px;
  background-color: ${colors.white};
  width: 100%;
  box-sizing: border-box;
  height: fit-content;
  padding-bottom: 20px;
  color: ${colors.navy};
`;

const TitleContainer = styled.div`
  display: flex;
  padding: 21px 50px;
  background-color: ${colors.mediumGrey};
  border-bottom: 1px solid ${colors.blueGrey};
  font-weight: bold;
  font-size: 18px;
  font-family: Roboto;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px 3px 0px 0px;
  margin-bottom: 20px;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 14px 24px 9px;
    font-size: 16px;
    margin-bottom: 10px;
  }
`;

const AlterationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px ${({ noPadding }) => noPadding ? '0' : '50'}px;

  @media (max-width: ${mobileThresholdPixels}) {
    ${({ noPadding }) => !noPadding && 'padding: 0px 24px;'}
  }
`;

const TextLineContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: Roboto;
  ${({ isTitle }) => isTitle && 'margin-top: 20px;'}
  font-size: 16px;
  margin-bottom: 20px;
  ${({ isCentered }) => isCentered && 'align-items: center;'}

  @media (max-width: ${mobileThresholdPixels}) {
    ${({ isTitle }) => !isTitle && 'align-items: flex-start;'}
  }
`;

const Line = styled.div`
  flex-grow: 1;
  border-bottom: 1px solid ${colors.blueGrey};
  margin-left: 10px;

  @media (max-width: ${mobileThresholdPixels}) {
    min-width: 40px;
  }
`;

const TextContainer = styled.div`
  ${({ isBold }) => isBold && 'font-weight: bold;'}
  ${({ isTitle }) => isTitle && 'margin-bottom: -5px;'}
  max-width: 417px;
  line-height: 21px;

  @media (max-width: ${mobileThresholdPixels}) {
    ${({ isBold, isTitle, isLeft }) => isBold && !isTitle && !isLeft && 'margin-left: 33px;'}
  }
`;

const UnderlinedText = styled.div`
  text-decoration: underline;
  cursor: pointer;
  text-align: right;
  width: 100%;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 10px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 16px;
    text-align: left;
    margin-top: 40px;
  }
`;

const PricesList = ({ subCategory, openAllPricings, context: { isMobile } }) => {
  const item = items[subCategory];
  return (
    <Container>
      <PricesContainer>
        <TitleContainer>
          <div>{item.label}</div>
          <div>Tarifs</div>
        </TitleContainer>
        {item.category !== 'creation' && (
          <AlterationsContainer>
            <TextLineContainer isTitle>
              <TextContainer isBold isTitle>Retouches les plus courantes</TextContainer>
              <Line />
            </TextLineContainer>
            {item.works.ordinaryAlterations.map(({ id, label }) => (
              <TextLineContainer isCentered key={id}>
                <TextContainer>{label}</TextContainer>
                <TextContainer isBold>{pricingInverse[id].price} €</TextContainer>
              </TextLineContainer>
            ))}
            <TextLineContainer isTitle>
              <TextContainer isBold isTitle>Broderie</TextContainer>
              <Line />
            </TextLineContainer>
            {item.works.embroideries.map((id, index) => (
              <TextLineContainer isCentered key={id}>
                <TextContainer>{embroideriesLabels[index]}</TextContainer>
                <TextContainer isBold>{pricingInverse[id].price} €</TextContainer>
              </TextLineContainer>
            ))}
            <TextLineContainer isTitle>
              <TextContainer isBold isTitle>Upcycling</TextContainer>
              <Line />
            </TextLineContainer>
            <TextLineContainer isCentered>
              <TextContainer>
                <div>
                  {`Envie de modifier totalement l'aspect de votre ${item.label.toLowerCase().replace(' et ', '/')}`}
                  {' ?'}
                </div>
                <div>Parlez-en à notre couturier sur place lors du RDV !</div>
              </TextContainer>
              {!isMobile && <TextContainer isBold>Devis sur place</TextContainer>}
            </TextLineContainer>
            {isMobile && <TextContainer isBold isLeft>Devis sur place</TextContainer>}
            <UnderlinedText onClick={openAllPricings}>Accéder à la grille complète des tarifs</UnderlinedText>
          </AlterationsContainer>
        )}
        {item.category === 'creation' && item.handles && (
          <AlterationsContainer>
            {item.sizes.map(size => (
              <AlterationsContainer key={size.id} noPadding>
                <TextLineContainer isTitle>
                  <TextContainer isBold isTitle>{size.label}</TextContainer>
                  <Line />
                </TextLineContainer>
                {item.handles?.map(handle => (
                  <TextLineContainer isCentered key={handle.id}>
                    <TextContainer>{handle.label}</TextContainer>
                    <TextContainer isBold>
                      {pricingInverse[size.id].price + pricingInverse[handle.id].price} €
                    </TextContainer>
                  </TextLineContainer>
                ))}
              </AlterationsContainer>
            ))}
            <AlterationsContainer noPadding>
              <TextLineContainer isTitle>
                <TextContainer isBold isTitle>Fermeture en option</TextContainer>
                <Line />
              </TextLineContainer>
              {item.closures?.map(closure => (
                <TextLineContainer isCentered key={closure.id}>
                  <TextContainer>{closure.label}</TextContainer>
                  <TextContainer isBold>
                    + {pricingInverse[closure.id].price} €
                  </TextContainer>
                </TextLineContainer>
              ))}
            </AlterationsContainer>
          </AlterationsContainer>
        )}
        {item.category === 'creation' && item.handles && item.noHandleOption && (
          <AlterationsContainer>
            <TextLineContainer isTitle>
              <TextContainer isBold isTitle>Taille de {item.label.toLowerCase()}</TextContainer>
              <Line />
            </TextLineContainer>
            {item.sizes.map(size => (
              <TextLineContainer isCentered key={size.id}>
                <TextContainer>{size.label}</TextContainer>
                <TextContainer isBold>
                  {pricingInverse[size.id].price} €
                </TextContainer>
              </TextLineContainer>
            ))}
            <TextLineContainer isTitle>
              <TextContainer isBold isTitle>Anse en option</TextContainer>
              <Line />
            </TextLineContainer>
            {item.handles?.map(handle => (
              <TextLineContainer isCentered key={handle.id}>
                <TextContainer>{handle.label}</TextContainer>
                <TextContainer isBold>
                  + {pricingInverse[handle.id].price} €
                </TextContainer>
              </TextLineContainer>
            ))}
            <TextLineContainer isTitle>
              <TextContainer isBold isTitle>Fermeture en option</TextContainer>
              <Line />
            </TextLineContainer>
            {item.closures?.map(closure => (
              <TextLineContainer isCentered key={closure.id}>
                <TextContainer>{closure.label}</TextContainer>
                <TextContainer isBold>
                  + {pricingInverse[closure.id].price} €
                </TextContainer>
              </TextLineContainer>
            ))}
          </AlterationsContainer>
        )}
        {item.category === 'creation' && !item.handles && (
          <AlterationsContainer>
            <TextLineContainer isTitle>
              <TextContainer isBold isTitle>Taille de la housse</TextContainer>
              <Line />
            </TextLineContainer>
            {item.sizes.map(size => (
              <TextLineContainer isCentered key={size.id}>
                <TextContainer>{size.label}</TextContainer>
                <TextContainer isBold>
                  {pricingInverse[size.id].price} €
                </TextContainer>
              </TextLineContainer>
            ))}
            <TextLineContainer isTitle>
              <TextContainer isBold isTitle>Fermeture en option</TextContainer>
              <Line />
            </TextLineContainer>
            {item.closures?.map(closure => (
              <TextLineContainer isCentered key={closure.id}>
                <TextContainer>{closure.label}</TextContainer>
                <TextContainer isBold>
                  + {pricingInverse[closure.id].price} €
                </TextContainer>
              </TextLineContainer>
            ))}
          </AlterationsContainer>
        )}
      </PricesContainer>
      {item.customerFeedback && (
        <FeedbackContainer>
          <Feedback isFull feedback={item.customerFeedback} isTransparent />
        </FeedbackContainer>
      )}
    </Container>
  );
};

PricesList.propTypes = {
  subCategory: PropTypes.string.isRequired,
  openAllPricings: PropTypes.func.isRequired,
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
};

export default withAppContext(PricesList);
