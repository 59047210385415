import customerFeedbacks from './customerFeedbacks';

export const embroideriesLabels = [
  'Initiales',
  'Mot entier écrit en petite police',
  'Dessin creux de moins de 5cm',
  'Dessin plein simple de moins de 5cm',
];

export const items = {
  pant: {
    label: 'Pantalon',
    category: 'cloth',
    works: {
      ordinaryAlterations: [
        { label: 'Ourlet simple', id: '7.pant-rac-1' },
        { label: 'Bas de jambe à cintrer', id: '7.pant-cint-3' },
        { label: 'Ceinture à cintrer', id: '7.pant-cint-1' },
        { label: 'Fermeture éclair à ajouter, remplacer ou retirer', id: '7.pant-div-1' },
        { label: 'Couture simple décousue à reprendre', id: '7.pant-div-10' },
        { label: 'Déchirure à réparer', id: '7.pant-div-7' },
      ],
      embroideries: ['7.pant-bro-1', '7.pant-bro-2', '7.pant-bro-7', '7.pant-bro-10'],
    },
  },
  pant_complex: {
    label: 'Pantalon de tailleur',
    category: 'cloth',
    works: {
      ordinaryAlterations: [
        { label: 'Ourlet simple', id: '7.pant-rac-3' },
        { label: 'Bas de jambe à cintrer', id: '7.pant-cint-7' },
        { label: 'Ceinture à cintrer', id: '7.pant-cint-5' },
        { label: 'Fermeture éclair à ajouter, remplacer ou retirer', id: '7.pant-div-13' },
        { label: 'Couture simple décousue à reprendre', id: '7.pant-div-22' },
        { label: 'Déchirure à réparer', id: '7.pant-div-19' },
      ],
      embroideries: ['7.pant-bro-4', '7.pant-bro-5', '7.pant-bro-14', '7.pant-bro-17'],
    },
    customerFeedback: customerFeedbacks.pant,
  },
  robe: {
    label: 'Robe',
    category: 'cloth',
    works: {
      ordinaryAlterations: [
        { label: 'Bustier ou taille à cintrer', id: '7.robe-cin-5' },
        { label: 'Ourlet simple', id: '7.robe-rac-1' },
        { label: 'Fermeture éclair à ajouter, remplacer ou retirer', id: '7.robe-div-2' },
        { label: 'Déchirure à réparer', id: '7.robe-div-11' },
        { label: 'Couture simple décousue à reprendre', id: '7.robe-div-13' },
      ],
      embroideries: ['7.robe-bro-1', '7.robe-bro-2', '7.robe-bro-7', '7.robe-bro-10'],
    },
  },
  jupe: {
    label: 'Jupe',
    category: 'cloth',
    works: {
      ordinaryAlterations: [
        { label: 'Ceinture à cintrer', id: '7.jupe-cin-1' },
        { label: 'Ourlet simple', id: '7.jupe-rac-1' },
        { label: 'Fermeture éclair à ajouter, remplacer ou retirer', id: '7.jupe-div-2' },
        { label: 'Déchirure à réparer', id: '7.jupe-div-10' },
        { label: 'Couture simple décousue à reprendre', id: '7.jupe-div-12' },
      ],
      embroideries: ['7.jupe-bro-1', '7.jupe-bro-2', '7.jupe-bro-7', '7.jupe-bro-10'],
    },
  },
  mari: {
    label: 'Robe de mariée',
    category: 'cloth',
    works: {
      ordinaryAlterations: [
        { label: 'Ourlet simple', id: '7.mari-rac-1' },
        { label: 'Ourlet à volants', id: '7.mari-rac-2' },
        { label: 'Bustier ou taille à cintrer', id: '7.mari-cin-5' },
        { label: 'Dos à cintrer ou élargir', id: '7.mari-cin-6' },
        { label: 'Épaules à cintrer', id: '7.mari-cin-2' },
        { label: 'Doublure à ajouter, remplacer ou retirer', id: '7.mari-div-1' },
        { label: 'Fente à ajouter', id: '7.mari-div-6' },
        { label: 'Pince à ajouter', id: '7.mari-div-7' },
      ],
      embroideries: ['7.mari-bro-1', '7.mari-bro-2', '7.mari-bro-4', '7.mari-bro-7'],
    },
    customerFeedback: customerFeedbacks.mari,
  },
  robe_complex: {
    label: 'Robe de soirée',
    category: 'cloth',
    works: {
      ordinaryAlterations: [
        { label: 'Bustier ou taille à cintrer', id: '7.robe-cin-11' },
        { label: 'Ourlet simple', id: '7.robe-rac-7' },
        { label: 'Fermeture éclair à ajouter, remplacer ou retirer', id: '7.robe-div-17' },
        { label: 'Déchirure à réparer', id: '7.robe-div-28' },
        { label: 'Couture simple décousue à reprendre', id: '7.robe-div-30' },
      ],
      embroideries: ['7.robe-bro-4', '7.robe-bro-5', '7.robe-bro-14', '7.robe-bro-17'],
    },
    customerFeedback: customerFeedbacks.robe,
  },
  mant: {
    label: 'Veste et manteau',
    category: 'cloth',
    works: {
      ordinaryAlterations: [
        { label: 'Dos à cintrer', id: '7.mant-cin-4' },
        { label: 'Longueur à raccourcir', id: '7.mant-rac-1' },
        { label: 'Bouton à ajouter ou remplacer', id: '7.mant-div-12' },
        { label: 'Déchirure à réparer', id: '7.mant-div-13' },
        { label: 'Couture simple décousue à reprendre', id: '7.mant-div-15' },
      ],
      embroideries: ['7.mant-bro-1', '7.mant-bro-2', '7.mant-bro-4', '7.mant-bro-7'],
    },
    customerFeedback: customerFeedbacks.mant,
  },
  haut: {
    label: 'Haut',
    category: 'cloth',
    works: {
      ordinaryAlterations: [
        { label: 'Dos à cintrer', id: '7.haut-cin-4' },
        { label: 'Longueur à raccourcir', id: '7.haut-rac-1' },
        { label: 'Bouton à ajouter ou remplacer', id: '7.haut-div-10' },
        { label: 'Déchirure à réparer', id: '7.haut-div-11' },
        { label: 'Couture simple décousue à reprendre', id: '7.haut-div-13' },
      ],
      embroideries: ['7.haut-bro-1', '7.haut-bro-2', '7.haut-bro-7', '7.haut-bro-10'],
    },
  },
  pull: {
    label: 'Pull et gilet',
    category: 'cloth',
    works: {
      ordinaryAlterations: [
        { label: 'Dos à cintrer', id: '7.pull-cin-5' },
        { label: 'Coudières à ajouter ou remplacer', id: '7.pull-div-2' },
        { label: 'Bouton à ajouter ou remplacer', id: '7.pull-div-10' },
        { label: 'Déchirure à réparer', id: '7.pull-div-11' },
        { label: 'Couture simple décousue à reprendre', id: '7.pull-div-13' },
      ],
      embroideries: ['7.pull-bro-1', '7.pull-bro-2', '7.pull-bro-7', '7.pull-bro-10'],
    },
  },
  pull_complex: {
    label: 'Pull en laine ou cachemire',
    category: 'cloth',
    works: {
      ordinaryAlterations: [
        { label: 'Dos à cintrer', id: '7.pull-cin-10' },
        { label: 'Coudières à ajouter ou remplacer', id: '7.pull-div-17' },
        { label: 'Bouton à ajouter ou remplacer', id: '7.pull-div-25' },
        { label: 'Déchirure à réparer', id: '7.pull-div-26' },
        { label: 'Couture simple décousue à reprendre', id: '7.pull-div-28' },
      ],
      embroideries: ['7.pull-bro-4', '7.pull-bro-5', '7.pull-bro-14', '7.pull-bro-17'],
    },
  },
  haut_complex: {
    label: 'Chemise',
    category: 'cloth',
    works: {
      ordinaryAlterations: [
        { label: 'Dos à cintrer', id: '7.haut-cin-8' },
        { label: 'Longueur à raccourcir', id: '7.haut-rac-4' },
        { label: 'Bouton à ajouter ou remplacer', id: '7.haut-div-25' },
        { label: 'Déchirure à réparer', id: '7.haut-div-26' },
        { label: 'Couture simple décousue à reprendre', id: '7.haut-div-28' },
      ],
      embroideries: ['7.haut-bro-4', '7.haut-bro-5', '7.haut-bro-14', '7.haut-bro-17'],
    },
    customerFeedback: customerFeedbacks.haut,
  },
  ling: {
    label: 'Lingerie et maillot de bain',
    category: 'cloth',
    works: {
      ordinaryAlterations: [
        { label: 'Ourlet', id: '7.ling-rac-3' },
        { label: 'Bretelles à ajouter ou remplacer', id: '7.ling-div-3' },
        { label: 'Déchirure à réparer', id: '7.ling-div-7' },
        { label: 'Élastique à ajouter ou remplacer', id: '7.ling-div-8' },
        { label: 'Couture simple décousue à reprendre', id: '7.ling-div-12' },
      ],
      embroideries: ['7.ling-bro-1', '7.ling-bro-2', '7.ling-bro-7', '7.ling-bro-10'],
    },
    customerFeedback: customerFeedbacks.ling,
  },
  acce: {
    label: 'Accessoires',
    category: 'cloth',
    works: {
      ordinaryAlterations: [
        { label: 'Bouton à ajouter ou remplacer', id: '7.acce-div-3' },
        { label: 'Déchirure à réparer', id: '7.acce-div-4' },
        { label: 'Couture simple décousue à reprendre', id: '7.acce-div-6' },
      ],
      embroideries: ['7.acce-bro-1', '7.acce-bro-2', '7.acce-bro-7', '7.acce-bro-10'],
    },
  },
  ride: {
    label: 'Rideau',
    category: 'deco',
    works: {
      ordinaryAlterations: [
        { label: 'Ourlet pour un rideau de moins de 2m de large', id: '7.deco-rid-1' },
        { label: 'Couture simple décousue à reprendre', id: '7.deco-rid-3' },
        { label: 'Déchirure à réparer', id: '7.deco-rid-5' },
      ],
      embroideries: ['7.deco-bro-1', '7.deco-bro-2', '7.deco-bro-4', '7.deco-bro-7'],
    },
    customerFeedback: customerFeedbacks.ride,
  },
  cous: {
    label: 'Housse',
    category: 'deco',
    works: {
      ordinaryAlterations: [
        { label: 'Fermeture éclair à ajouter, remplacer ou retirer', id: '7.deco-cou-3' },
        { label: 'Déchirure à réparer', id: '7.deco-cou-5' },
        { label: 'Couture simple décousue à reprendre', id: '7.deco-cou-6' },
      ],
      embroideries: ['7.deco-bro-1', '7.deco-bro-2', '7.deco-bro-4', '7.deco-bro-7'],
    },
  },
  mais: {
    label: 'Linge de maison',
    category: 'deco',
    works: {
      ordinaryAlterations: [
        { label: 'Côté de moins d\'1m à raccourcir', id: '7.deco-mai-7' },
        { label: 'Côté de moins d\'1m60 à raccourcir', id: '7.deco-mai-4' },
        { label: 'Côté de plus d\'1m60 à raccourcir', id: '7.deco-mai-1' },
        { label: 'Fermeture éclair à ajouter, remplacer ou retirer', id: '7.deco-mai-6' },
        { label: 'Déchirure à réparer', id: '7.deco-mai-14' },
        { label: 'Couture simple décousue à reprendre', id: '7.deco-mai-12' },
      ],
      embroideries: ['7.deco-bro-1', '7.deco-bro-2', '7.deco-bro-4', '7.deco-bro-7'],
    },
  },
  bag: {
    label: 'Sac en tissu',
    category: 'creation',
    sizes: [
      { label: 'Sac de petite taille (20 x 30cm)', id: '7.crea_sac-dime-1' },
      { label: 'Sac de taille moyenne (30 x 30cm)', id: '7.crea_sac-dime-2' },
      { label: 'Sac de grande taille (plus de 30 x 30cm)', id: '7.crea_sac-dime-3' },
    ],
    handles: [
      { label: 'Anse en chaîne', id: '7.crea_sac-anse-1' },
      { label: 'Anse en tissu', id: '7.crea_sac-anse-4' },
    ],
    closures: [
      { label: 'Bouton classique', id: '7.crea_sac-ferm-7' },
      { label: 'Bouton pression', id: '7.crea_sac-ferm-4' },
      { label: 'Fermeture éclair', id: '7.crea_sac-ferm-1' },
    ],
  },
  tote: {
    label: 'Tote bag',
    category: 'creation',
    sizes: [
      { label: 'Tote bag de taille classique (35 x 40cm)', id: '7.crea_tote-dime-1' },
      { label: 'Tote bag de grande taille (40 x 50cm)', id: '7.crea_tote-dime-2' },
    ],
    handles: [
      { label: 'Anse en chaîne', id: '7.crea_tote-anse-1' },
      { label: 'Anse en tissu', id: '7.crea_tote-anse-3' },
    ],
    closures: [
      { label: 'Bouton classique', id: '7.crea_sac-ferm-5' },
      { label: 'Bouton pression', id: '7.crea_sac-ferm-3' },
      { label: 'Fermeture éclair', id: '7.crea_sac-ferm-1' },
    ],
  },
  clut: {
    label: 'Pochette',
    category: 'creation',
    noHandleOption: true,
    sizes: [
      { label: 'Pochette de petite taille (15 x 10cm)', id: '7.crea_poch-dime-1' },
      { label: 'Pochette de taille moyenne (20 x 20cm)', id: '7.crea_poch-dime-2' },
      { label: 'Pochette de grande taille (plus de 25 x 25cm)', id: '7.crea_poch-dime-3' },
    ],
    handles: [
      { label: 'Anse en chaîne', id: '7.crea_poch-anse-1' },
      { label: 'Anse en tissu', id: '7.crea_poch-anse-4' },
    ],
    closures: [
      { label: 'Bouton classique', id: '7.crea_poch-ferm-7' },
      { label: 'Bouton pression', id: '7.crea_poch-ferm-4' },
      { label: 'Fermeture éclair', id: '7.crea_poch-ferm-1' },
    ],
  },
  cous2: {
    label: 'Housse',
    category: 'creation',
    sizes: [
      { label: 'Housse de moins de 45 cm', id: '7.crea_hous-dime-1' },
      { label: 'Housse de moins de plus de 45 cm', id: '7.crea_hous-dime-2' },
    ],
    closures: [
      { label: 'Fermeture éclair', id: '7.crea_hous-ferm-1' },
    ],
  },
};

export const getSubCategories = category => (
  Object.keys(items)
    .filter(item => items[item].category === category)
    .map(item => ({ slug: item, label: items[item].label }))
);

export const getSubCategory = subCategory => items[subCategory];
