import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import withAppContext from '../../../withAppContext';
import {
  margins, colors, Title1 as StyledTitle1, Button as StyledButton, mobileThresholdPixels,
} from './styledComponents';
import Dots from './Dots';
import { items } from './pricesTree';
import { pricingInverse } from '../../../services/pricing';
import imageCushion from '../../../assets/order/creations/cushion.jpg';
import imageBag from '../../../assets/order/creations/bag.jpg';
import imageClutchBag from '../../../assets/order/creations/clutchBag.jpg';
import CTALink from '../../CTALink';

const Title1 = styled(StyledTitle1)`
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 18px;
    margin: 0px 24px;
  }
`;

const Button = styled(StyledButton)`
  width: 245px;
  font-size: 18px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
  }
`;

const Container = styled.div`
  background-color: ${colors.white};
  color: ${colors.navy};
  width: 100%;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;

  @media (max-width: ${mobileThresholdPixels}) {
    padding-top: 47px;
    padding-bottom: 56px;
  }
`;

const SubContainer = styled.div`
  max-width: 994px;
  margin-top: 35px;
  display: flex;
  justify-content: space-between;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    width: 100%;
    margin-top: -15px;
    padding: 0px 24px;
    box-sizing: border-box;
    max-width: unset;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Roboto;
  font-family: Roboto;
  font-size: 18px;
  line-height: 27px;
  max-width: 385px;
  margin-right: 122px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    margin-right: 0px;
    margin-left: 0px;
    max-width: unset;
  }
`;

const Title = styled.div`
  font-family: Libre Baskerville;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 30px;
  font-weight: bold;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const Bold = styled.span`
  font-weight: bold;
`;

const Span = styled.span`
  margin-bottom: 10px;
`;

const PricesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 55px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 11px
    margin-bottom: 44px;
  }
`;

const PriceContainer = styled.div`
  display: flex;
  line-height: 18px;
  margin-bottom: 10px;
`;

const DottedLine = styled.div`
  flex-grow: 1;
  border-bottom: 2px dotted ${colors.navy};
  margin: 0px 5px 3px;
`;

const ImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;


const ImagesSubContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 29px;
  }
`;

const FirstImage = styled.img`
  width: 100%;
  max-height: 279px;
  margin-bottom: 20px;
  object-fit: cover;
`;

const Image = styled.img`
  object-fit: cover;
  width: ${({ width }) => width || 100}%;
  height: ${({ height }) => height ? `${height}%` : 'auto'};
  ${({ marginRight }) => marginRight && 'margin-right: 20px;'}
  ${({ flexGrow }) => flexGrow && 'flex-grow: 1; width: unset;'}
  margin-bottom: 20px;

  @media (max-width: ${mobileThresholdPixels}) {
    height: ${({ big }) => big ? 184 : 120}px;
  }
`;

const ButtonContainer = styled.div`
  margin: ${margins.xl} 0px 0px 0px;
  text-align: center;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.m} 0px 0px 0px;
  }
`;

const ButtonCTA = styled(StyledButton)`
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 20px;
    width: calc(100vw - 48px);
  }
`;

const CTAButton = ({ label }) => (
  <ButtonContainer>
    <ButtonCTA navy>
      {label}
    </ButtonCTA>
  </ButtonContainer>
);

CTAButton.propTypes = {
  label: PropTypes.string,
};

CTAButton.defaultProps = {
  label: 'Prendre rendez-vous',
};

const PricingFooter = ({ openAllPricings, context: { isMobile }, isWebView }) => (
  <Container>
    <Title1 lowercase fontSize={'32px'}>Créez votre propre accessoire avec nos Tillistes</Title1>
    <Dots />
    <SubContainer>
      {isMobile && <Image src={imageCushion} alt="Coussins" big />}
      <TextContainer>
        <Title>Donnez vie à vos tissus préférés</Title>
        <Span>
          Nos Tillistes réalisent des accessoires avec les tissus de votre choix.
        </Span>
        <Span>
          Choisissez le type d’anse et le type de fermeture. <Bold>
            Obtenez en 3 jours un nouvel accessoire unique et pensé par vous !
          </Bold>
        </Span>
        {isMobile && (
          <ImagesSubContainer>
            <Image src={imageBag} alt="Sac" marginRight width={45} />
            <Image src={imageClutchBag} alt="Pochette" flexGrow />
          </ImagesSubContainer>
        )}
        <PricesContainer>
          {Object.values(items)
            .filter(item => item.category === 'creation')
            .map(({ label, handles, sizes }) => (
              {
                label,
                price: handles
                  ? pricingInverse[handles[1].id].price + pricingInverse[sizes[0].id].price
                  : pricingInverse[sizes[0].id].price,
              }))
            .sort((itemA, itemB) => itemA.price - itemB.price)
            .map(item => (
              <PriceContainer key={item.label}>
                {item.label}<DottedLine />à partir de {item.price} €
              </PriceContainer>
            ))
          }
        </PricesContainer>
        <Button onClick={openAllPricings}>Voir tous les tarifs</Button>
      </TextContainer>
      {!isMobile && (
        <ImagesContainer>
          <FirstImage src={imageCushion} alt="Coussins" />
          <ImagesSubContainer>
            <Image src={imageBag} alt="Sac" marginRight width={45} />
            <Image src={imageClutchBag} alt="Pochette" flexGrow />
          </ImagesSubContainer>
        </ImagesContainer>
      )}
    </SubContainer>
    {!isWebView && (
      <CTALink fromInteraction="Pricings">
        <CTAButton />
      </CTALink>
    )}
  </Container>
);

PricingFooter.propTypes = {
  openAllPricings: PropTypes.func,
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
  isWebView: PropTypes.bool,
};

PricingFooter.defaultProps = {
  openAllPricings() {},
  isWebView: false,
};

export default withAppContext(PricingFooter);
