import React from 'react';
import PropTypes from 'prop-types';

import withAppContext from '../../withAppContext';
import MenuBar from '../../components/home/MenuBar';
import Footer from '../../components/home/v3/Footer';
import Pricings from '../../components/home/v3/Pricings';
import PricingsFAQ from '../../components/home/v3/PricingsFAQ';
import PricingFooter from '../../components/home/v3/PricingFooter';
import PricingsModal from '../../components/home/v2/PricingsModal';
import PricingCategoriesSelector from '../../components/home/v3/PricingCategoriesSelector';
import { Container, Content } from '../../components/home/v3/styledComponents';
import Layout from '../../layouts/index';
import pricings from '../../services/pricing';

class PricingsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAllPricings: props.isOpen,
      showMobilePricingCategoriesSelector: false,
      selectedCategory: 'cloth',
      selectedSubCategory: 'pant',
    };
    this.selectCategory = this.selectCategory.bind(this);
  }

  componentDidUpdate(_, prevState) {
    const { context: { isMobile } } = this.props;
    const { showMobilePricingCategoriesSelector, selectedSubCategory, showAllPricings } = this.state;
    if (isMobile
      && ((showMobilePricingCategoriesSelector
      && showMobilePricingCategoriesSelector !== prevState.showMobilePricingCategoriesSelector)
      || (selectedSubCategory !== prevState.selectedSubCategory)
      || (showAllPricings !== prevState.showAllPricings))) {
      window.scrollTo(0, 0);
    }
  }

  selectCategory(slug) {
    const { selectedCategory } = this.state;
    this.setState({ selectedCategory: slug === selectedCategory ? '' : slug });
  }

  renderWithMobilePricingCategoriesSelector() {
    const { selectedCategory, selectedSubCategory } = this.state;
    return (
      <Layout routeSlug="PricingsPage">
        <Container>
          <MenuBar />
          <Content>
            <PricingCategoriesSelector
              selectedCategory={selectedCategory}
              selectedSubCategory={selectedSubCategory}
              selectCategory={this.selectCategory}
              selectSubCategory={subCategory => (
                this.setState({ selectedSubCategory: subCategory, showMobilePricingCategoriesSelector: false })
              )}
              hideMobilePricingCategoriesSelector={() => (
                this.setState({ showMobilePricingCategoriesSelector: false })
              )}
            />
          </Content>
        </Container>
      </Layout>
    );
  }

  render() {
    const { showAllPricings, showMobilePricingCategoriesSelector, selectedCategory, selectedSubCategory } = this.state;
    if (showMobilePricingCategoriesSelector) {
      return this.renderWithMobilePricingCategoriesSelector();
    }
    return (
      <Layout routeSlug="PricingsPage">
        {showAllPricings ?
          <PricingsModal
            pricings={pricings}
            close={() => this.setState({ showAllPricings: false })}
          /> :
          <Container>
            <MenuBar />
            <Content>
              <Pricings
                openAllPricings={() => this.setState({ showAllPricings: true })}
                selectedCategory={selectedCategory}
                selectedSubCategory={selectedSubCategory}
                selectCategory={this.selectCategory}
                selectSubCategory={subCategory => this.setState({ selectedSubCategory: subCategory })}
                showMobilePricingCategoriesSelector={() => this.setState({ showMobilePricingCategoriesSelector: true })}
              />
            </Content>
            <Content><PricingsFAQ /></Content>
            <Content>
              <PricingFooter openAllPricings={() => this.setState({ showAllPricings: true })} />
            </Content>
            <Footer />
          </Container>
        }
      </Layout>
    );
  }
}

PricingsPage.propTypes = {
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
  isOpen: PropTypes.bool,
};

PricingsPage.defaultProps = {
  isOpen: false,
};

export default withAppContext(PricingsPage);
