import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button, Title1, Title2 } from './styledComponents';
import ClothesPricingSelector from './ClothesPricingSelector';
import PricingsGrid from './PricingsGrid';
import { mobileThresholdPixels } from '../../../tilli-components/src/components/styledComponents';

import pant from '../../../assets/clothes/jeans.png';
import haut from '../../../assets/clothes/shirt.png';
import robe from '../../../assets/clothes/dress.png';
import pull from '../../../assets/clothes/pullover.png';
import mant from '../../../assets/clothes/jacket.png';
import jupe from '../../../assets/clothes/skirt.png';
import ling from '../../../assets/clothes/panties.png';
import deco from '../../../assets/clothes/window.png';
import mari from '../../../assets/clothes/wedding.png';
import acce from '../../../assets/clothes/bag.png';

const clothesImages = { pant, haut, robe, pull, mant, jupe, ling, deco, mari, acce };

const Container = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 150px;
  }
`;

const TitleContainer = styled.div`
  max-width: 800px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const StyledTitle2 = styled(Title2)`
  text-align: center;
  margin-bottom: 44px;
`;

const Styled2Title2 = styled(Title2)`
  text-align: center;
  margin-top: 100px;
  margin-bottom: 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 77px 20px 0px;
  }
`;

const Styled3Title2 = styled(Title2)`
  margin-top: 50px;
  margin-bottom: 20px;
`;

const Styled4Title2 = styled(Title2)`
  margin-top: 70px;
  margin-bottom: 20px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
`;

const ClothImg = styled.img`
  height: 40px;
  margin-left: 10px;

  @media (max-width: ${mobileThresholdPixels}) {
    display: none;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  max-width: 800px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const Text = styled.div``;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

function getCloth(pricings, slug) {
  return pricings.find(cloth => cloth.slug === slug);
}

class PricingsModal extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { pricings, close } = this.props;
    const { selectedClothSlug } = this.state;
    const cloth = selectedClothSlug && getCloth(pricings, selectedClothSlug);
    return (
      <Container>
        <TitleContainer>
          <Title1>
            grille complète de tarifs
          </Title1>

          <StyledTitle2>
            Choisissez le vêtement que vous souhaitez faire retoucher
          </StyledTitle2>
        </TitleContainer>

        <ClothesPricingSelector
          pricings={pricings}
          setCloth={slug => this.setState({ selectedClothSlug: slug })}
          selectedClothSlug={selectedClothSlug}
        />

        {selectedClothSlug && (
          <div>
            <Row>
              <Styled2Title2>
                Grille des tarifs de retouches de {cloth.name}
              </Styled2Title2>
              <ClothImg src={clothesImages[selectedClothSlug]} />
            </Row>

            <PricingsGrid cloth={cloth} />

            <TextContainer>
              {cloth.types && cloth.types.length > 0 &&
                <Styled3Title2>La distinction entre simple et habillé</Styled3Title2>
              }

              {cloth.types && cloth.types.length > 0 &&
                <Text>
                  Les prix sont fixés en fonction du travail que cela demande à nos
                  couturières. Nous proposons pour la plupart des vêtements deux tarifs :
                  le basique / simple ou l’habillé / complexe. On distingue les deux car la
                  retouche sur un vêtement habillé demandera plus de travail à nos
                  couturières et donc justifie un prix plus élevé. Pour bien distinguer
                  les deux, rien ne vaut des exemples :<br />
                  - Simple : une robe casual, un chino, un top<br />
                  - Habillé / tailleur : un pantalon en soie, une robe de cocktail,
                  un pantalon de tailleur.
                </Text>
              }

              <Styled4Title2>Quid de la doublure</Styled4Title2>

              <Text>
                Une doublure c’est le tissu situé à l’intérieur de votre vêtement.
                Pour toute retouche, si votre vêtement contient une doublure à l‘intérieur,
                cela entraîne un travail supplémentaire pour le couturier, donc un
                ajustement des prix. Nous avons indiqué dans le tableau le coût
                supplémentaire que la doublure engendre. Il est à additionner avec le prix
                de la retouche. À noter aussi que certains vêtements complexes peuvent avoir
                plusieurs doublures. Dans ce cas, il faudra additionner au prix de la
                retouche les coûts de toutes les doublures.
              </Text>
            </TextContainer>
          </div>
        )}
        <ButtonContainer>
          <Button onClick={close}>Fermer la grille</Button>
        </ButtonContainer>
      </Container>
    );
  }
}

PricingsModal.propTypes = {
  pricings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  close: PropTypes.func.isRequired,
};

export default PricingsModal;
